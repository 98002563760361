<template>
  <div
    v-if="page"
    class="page-content"
  >
    <MainHeader
      :description="page.description || ''"
      :module="page.module || ''"
      :page-title="page.title || ''"
    />
    <wrapper
      v-if="done"
      class="content-page"
    >
      <div class="container-filters">
        <form
          v-if="showFilters"
          class="filter-area"
        >
          <s-select
            v-if="!isListLoaded"
            class="s-select-document"
            label="Filtro"
            loading
            :options="[]"
            size="large"
          />
          <div
            v-for="(dropDown, dropDownIndex) of dropDowns"
            v-else
            :key="dropDownIndex"
            class="category-drop-down"
          >
            <s-select
              v-model="dropDown.selectedDropdown"
              class="s-select-document"
              :label="dropDown.label ? dropDown.label : '-' "
              :loading="null"
              :options="dropDown.categories.map(item => item.value)"
              size="large"
              @select="onCategoryChange($event, dropDown, dropDownIndex)"
            />
          </div>
          <s-button
            icon="search"
            :loading="loading"
            size="large"
            @click="selectDocument()"
          />
        </form>
      </div>

      <div class="doc-wrapper">
        <ColorDocsMiniature
          v-for="item in allDocuments"
          :key="item.id"
          card-color="#5B7A95"
          :card-date="item.publicationAt"
          :card-download="getFileFullUrl(item.fileLink)"
          :card-title="loadCardTitle"
          :card-type="item.title"
          :card-year="cardYear"
        />
      </div>
      <EmptyState
        v-if="totalDocuments === 0 && !filtered"
        :image-src="require(`@/assets/img/examSearch.svg`)"
        img-empty-result-class
      >
        <h6 class="empty-desc">
          {{ $t('pages.pas.empty') }}
        </h6>
      </EmptyState>

      <EmptyState
        v-else-if="totalDocuments === 0 && filtered"
        :image-src="require(`@/assets/img/examSearch.svg`)"
        img-empty-result-class
      >
        <h3 class="empty-desc">
          {{ $t('pages.pas.noResult') }}
        </h3>
      </EmptyState>
    </wrapper>
    <wrapper
      v-else
      class="loading"
    >
      <spinner size="large" />
    </wrapper>
  </div>
</template>

<script>
import MainHeader from '@/components/shared/MainHeader.vue'
import ColorDocsMiniature from '@/components/shared/ColorDocsMiniature.vue'
import EmptyState from '@/components/shared/EmptyState.vue'

import documentsService from '@/services/documentsService'
import pageService from '@/services/pageService'

import { getFileFullUrl } from '@/utils/files'

export default {
  name: 'PageContent',
  components: {
    MainHeader,
    ColorDocsMiniature,
    EmptyState,
  },
  data() {
    return {
      loading: false,
      allDocuments: [],
      examDaysOptions: [],
      examsOptions: [],
      dropDowns: [],
      selectedDocumentGrade: null,
      selectedDocumentSubject: null,
      selectedDropdown: {},
      cardTitle: null,
      cardYear: null,
      docSearch: null,
      subCategoryKey: null,
      isListLoaded: false,
      filtered: false,
      showFilters: false,
      done: false,
      page: {},
    }
  },
  computed: {
    loadCardTitle() {
      return (this.dropDowns || []).map(
        (item) => item.selectedDropdown,
      ).join(' | ')
    },
    totalDocuments() {
      return this.allDocuments.length
    },
  },
  async created() {
    const { path } = this.$route
    const { data } = await pageService.getPageByUrl(
      path.replace('/paginas', ''),
    )
    this.page = data
    await this.getRootCategory()
  },
  methods: {
    async getRootCategory() {
      this.done = false
      try {
        this.isListLoaded = true
        const { data } = await documentsService.getDocumentsByRootCategoryId(
          this.page.rootCategory,
        )

        if (data.categories.length > 0) {
          this.showFilters = true
          const categories = await this.buildCategoryList(data.categories)
          this.dropDowns.push(categories)
        } else if ((data.documents || []).length > 0) {
          this.showFilters = false
          this.allDocuments = data.documents
        }
      } catch (error) {
        this.$toasted.global.error({
          message: `Erro ao carregar dados. ${error}`,
        })
      } finally {
        this.done = true
      }
    },
    buildCategoryList(categoryList) {
      if (categoryList.length > 0) {
        return {
          label: categoryList[0].name,
          categories: categoryList,
        }
      }

      return {}
    },
    async onCategoryChange(selectedItem, items, index) {
      this.clearSearch()
      try {
        if (index < this.dropDowns.length - 1) {
          this.dropDowns = this.dropDowns.splice(0, index + 1)
        }
        if (
          items.categories.filter(
            (item) => item.value === selectedItem,
          ).length > 0) {
          this.subCategoryKey = items.categories.filter(
            (item) => item.value === selectedItem,
          )[0].id
        }
        const { data } = await documentsService
          .getSubCategory(this.page.rootCategory, this.subCategoryKey)
        if (data.categories.length > 0) {
          const categories = await this.buildCategoryList(data.categories)
          this.dropDowns.push(categories || {})
        }
      } catch (error) {
        this.$toasted.global.error({
          message: `Erro ao carregar dados. ${error}`,
        })
      }
    },
    async selectDocument() {
      this.loading = true
      try {
        this.clearSearch()
        await this.getPasDocuments()
      } catch (error) {
        this.$toasted.global.error({
          message: this.$t('pages.pas.emptyFilters'),
        })
      } finally {
        this.loading = false
      }
    },
    async getPasDocuments() {
      if (!this.subCategoryKey) {
        this.$toasted.global.info({
          message: 'Selecione a categoria desejada para prosseguir.',
        })

        return
      }

      try {
        const { data } = await documentsService.getDocumentList(
          this.page.rootCategory, this.subCategoryKey,
        )

        this.filtered = true

        data.forEach((file) => {
          const fileTitle = file.title ? file.title : ''
          const searchTerm = this.docSearch ? this.docSearch : ''
          fileTitle.toLowerCase()
          searchTerm.toLowerCase()

          if (fileTitle.includes(searchTerm)) {
            this.allDocuments.push(file)
          }
        })
      } catch (error) {
        this.$toasted.global.error({
          message: `Erro ao carregar dados. ${error}`,
        })
      }
    },
    clearSearch() {
      this.allDocuments = []
    },
    getFileFullUrl,
  },
}
</script>

<style lang="sass">
.sas-wrapper.loading
  display: flex
  justify-content: center
  min-height: 300px

.sas-wrapper.content-page
  padding-bottom: 20px
  background: transparent
  padding-bottom: 20px

.doc-wrapper
  display: grid
  grid-gap: 32px

  +mq-m--mf
    grid-template-columns: repeat(2, 1fr)

  +mq-l--mf
    grid-template-columns: repeat(4, 1fr)

.main-subtitle
  display: inline-block
  margin-bottom: 36px
  font-size: 22px
  line-height: 28px
  color: #666E75

.simple-separator
  border: 0
  border-bottom: 1px solid #C4C4C4
  margin: 23px 0

.books-filter
  margin: 24px 0 32px 0
  display: inline

  .btn-content
    display: inline
    margin-right: 16px

.btn-book.sas-button
  margin-bottom: 14px
  box-shadow: none
  border: 1px solid #53595F

  &:not(:last-of-type)
    margin-right: 16px

  &:focus
    box-shadow: none !important

  &.active-book
    background: #666E75
    border: 1px solid #666E75 !important
    color: $color-white !important

.container-filters
  width: 100%
  display: flex
  align-items: flex-start
  flex-flow: wrap
  margin-bottom: 24px
  padding-top: 20px

  +mq-m--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px

  +mq-l--mf
    flex-direction: row
    grid-gap: 16px
    grid-template-columns: 35% 40% 5% 1fr
    padding-top: 20px
</style>
